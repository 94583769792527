import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { BasicButton } from "../../../components/Button";
import InputLabelContainer from "../../../components/Input/input";
import Modal from "../../../components/Modal";
import useModal from "../../../components/Modal/useModal";
import { Trash } from "../../../components/SVG";
import { ResponsiveTable } from "../../../components/Table";
import { db } from "../../../firebase";
import { useGetDoc } from "../../../firebase/methods";
import modalCSS from "../../../utils/StyleSheets/modal.module.css";
import "./tanks.css";

const defaultSelectValue = { prod_id: "0", name: "Select a product" };

const Tanks = ({ dlrID }) => {
  const [selectedTank, setSelectedTank] = useState();

  const {
    isOpen: isOpenNewUpdateModal,
    toggle: toggleNewUpdateModal,
    close: closeNewUpdateModal,
  } = useModal();
  const onCloseUpdate = () => {
    setSelectedTank();
    closeNewUpdateModal();
  };

  const {
    isOpen: isOpenDeleteModal,
    toggle: toggleDeleteModal,
    close: closeDeleteModal,
  } = useModal();
  const onCloseDelete = () => {
    setSelectedTank();
    closeDeleteModal();
  };

  const onClickEdit = (p) => {
    setSelectedTank(p);
    toggleNewUpdateModal();
  };

  const onClickDelete = (p) => {
    setSelectedTank(p);
    toggleDeleteModal();
    toggleNewUpdateModal();
  };

  const [tanks, setTanks] = useState([]);

  // const docRef = doc(db, "dealers", `${dlrID}`);
  const docRef = doc(db, `dealers/${dlrID}`);

  const fetchPost = async () => {
    try {
      const ps = await getDoc(docRef);
      if (ps.exists()) {
        const allProducts = ps.data().tanks;

        setTanks(allProducts);
      } else {
        console.log(`No such dealer ${dlrID} exists`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPost();
  }, []);

  return (
    <>
      <div className="dip__tanks section__padding">
        <div className="dip__tanks-content">
          <h2>Tanks</h2>

          <BasicButton
            title={"Add New Tank"}
            onClick={() => {
              toggleNewUpdateModal();
              setSelectedTank();
            }}
          />

          <ResponsiveTable r={tanks} onClickEdit={onClickEdit} />
        </div>
      </div>

      <Modal
        isOpen={isOpenNewUpdateModal}
        toggle={toggleNewUpdateModal}
        onEscape={onCloseUpdate}
        // close={onCloseUpdate}
      >
        <New_Update_Tank
          tank={selectedTank}
          tanks={tanks}
          setTanks={setTanks}
          docRef={docRef}
          toggle={toggleNewUpdateModal}
          dlrID={dlrID}
          onClickDelete={onClickDelete}
        />
      </Modal>

      <Modal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        onEscape={onCloseDelete}
        close={onCloseDelete}
      >
        <DeleteTank
          tank={selectedTank}
          tanks={tanks}
          setTanks={setTanks}
          docRef={docRef}
          toggle={toggleDeleteModal}
        />
      </Modal>
    </>
  );
};

const New_Update_Tank = ({
  tank,
  tanks,
  setTanks,
  docRef,
  toggle,
  dlrID,
  onClickDelete,
}) => {
  const [tank_id, set_tank_id] = useState(tank ? tank.tank_id : "");
  const [tank_color, set_tank_color] = useState(tank ? tank.color : "#becaee");
  const [tank_dia, set_tank_dia] = useState(tank ? tank.dia : "");
  const [tank_len, set_tank_len] = useState(tank ? tank.length : "");
  const [tank_p1, set_tank_p1] = useState(tank ? tank.p1 : 0);
  const [tank_p2, set_tank_p2] = useState(tank ? tank.p2 : 0);
  const [tank_deadwood, set_tank_deadwood] = useState(
    tank ? tank.deadwood : ""
  );
  //////////////////////////////////////////////////////////////////////////////////
  const [documents] = useGetDoc({
    path: `dealers/${dlrID}`,
    field: "products",
  });

  const oldProd =
    !!tank && documents.find((d) => `${d.prod_id}` === `${tank.prod_id}`);

  const initialProd = !!oldProd ? oldProd : defaultSelectValue;

  const [selectedProd, setSelectedProd] = useState("");

  useEffect(() => {
    setSelectedProd(initialProd);
  }, [initialProd, oldProd]);

  //////////////////////////////////////////////////////////////////////////////////

  const updateTank = async (e) => {
    e.preventDefault();

    const updateTankIndex =
      !!tank && tanks?.findIndex((p) => `${p.tank_id}` === `${tank.tank_id}`);

    if (`${selectedProd?.prod_id}` === "0") {
      alert("Please select a product");
      return;
    }

    let newObj = [];
    if (!tank_id) {
      alert("Please enter tank ID");
      return;
    }
    if (!tank_color) {
      alert("Please enter tank color");
      return;
    }
    if (!tank_dia) {
      alert("Please enter tank diameter");
      return;
    }
    if (!tank_len) {
      alert("Please enter tank length");
      return;
    }
    if (!tank_deadwood) {
      alert("Please enter tank deadwood");
      return;
    }

    const tankObj = {
      color: tank_color,
      dia: Number(tank_dia),
      length: Number(tank_len),
      p1: Number(tank_p1),
      p2: Number(tank_p2),
      deadwood: Number(tank_deadwood),
      prod_id: selectedProd.prod_id,
      tank_id: tank_id,
    };

    if (!!tank && updateTankIndex >= 0) {
      newObj = [...tanks];
      newObj[updateTankIndex] = tankObj;
      // console.log("newObj ", newObj);
    } else {
      newObj = [...(tanks && tanks.length > 0 ? tanks : []), tankObj];
    }

    try {
      await updateDoc(docRef, { tanks: newObj });
      setTanks(newObj);
      set_tank_id("");
      set_tank_color("");
      set_tank_dia("");
      set_tank_len("");
      set_tank_p1(0);
      set_tank_p2(0);
      set_tank_deadwood("");
      setSelectedProd(defaultSelectValue);
      toggle();
    } catch (e) {
      console.error(
        `Error ${updateTankIndex >= 0 ? "updating" : "adding"} : `,
        e
      );
    }
  };

  return (
    <>
      <h2 className={modalCSS.dip__modal_title}>
        {!tank ? "New" : "Update"} Tank
      </h2>

      {!!tank && (
        <div
          className={modalCSS.dip__modal_delete_text}
          onClick={() => onClickDelete(tank)}
        >
          <Trash size={16} color={"#FF4820"} />
          <span>Delete Tank ? </span>
        </div>
      )}

      {/* {!!oldProd && (
        <>
          <p>Chosen OLD Product :</p>
          <p>{oldProd.name}</p>
          </>
        )} */}

      <p>{tank_color}</p>
      <div>
        <InputLabelContainer label={"Color"}>
          {/* <input
            value={tank_color}
            type="text"
            placeholder="Name of new tank?"
            onChange={(e) => set_tank_color(e.target.value)}
          /> */}
          <input
            type="color"
            value={tank_color}
            onChange={(e) => set_tank_color(e.target.value)}
          />
        </InputLabelContainer>

        <InputLabelContainer label={"Pick Product:"}>
          <select
            value={selectedProd?.prod_id}
            style={{
              color:
                selectedProd?.name === defaultSelectValue.name
                  ? "gray"
                  : "black",
            }}
            onChange={(e) => {
              const prodID = e.target.value;
              if (prodID !== "0") {
                const selectedtank = documents.find(
                  (d) => `${d.prod_id}` === `${prodID}`
                );
                setSelectedProd(selectedtank);
              } else {
                setSelectedProd(selectedProd);
              }
            }}
          >
            <option
              value={defaultSelectValue.prod_id}
              style={{ color: "gray" }}
            >
              {defaultSelectValue.name}
            </option>
            {documents.map((d) => (
              <option key={d.prod_id} value={`${d.prod_id}`}>
                {d.name}
              </option>
            ))}
          </select>
        </InputLabelContainer>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <InputLabelContainer label={"Tank ID"}>
            <input
              value={tank_id}
              type="text"
              placeholder="Tank ID"
              onChange={(e) => set_tank_id(e.target.value)}
            />
          </InputLabelContainer>

          <div style={{ width: 20 }} />

          <InputLabelContainer label={"Deadwood"}>
            <input
              value={tank_deadwood}
              type="number"
              placeholder="Deadwood of new tank?"
              onChange={(e) => set_tank_deadwood(e.target.value)}
            />
          </InputLabelContainer>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <InputLabelContainer label={"Diameter"}>
            <input
              value={tank_dia}
              type="number"
              placeholder="Diameter of new tank?"
              onChange={(e) => set_tank_dia(e.target.value)}
            />
          </InputLabelContainer>

          <div style={{ width: 20 }} />

          <InputLabelContainer label={"Length"}>
            <input
              value={tank_len}
              type="number"
              placeholder="Length new tank?"
              onChange={(e) => set_tank_len(e.target.value)}
            />
          </InputLabelContainer>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <InputLabelContainer label={"P1"}>
            <input
              // style={{ marginRight: 20 }}
              value={tank_p1}
              type="number"
              placeholder="What is your new tank P1?"
              onChange={(e) => set_tank_p1(e.target.value)}
            />
          </InputLabelContainer>

          <div style={{ width: 20 }} />

          <InputLabelContainer label={"P2"}>
            <input
              value={tank_p2}
              type="number"
              placeholder="What is your new tank P2?"
              onChange={(e) => set_tank_p2(e.target.value)}
            />
          </InputLabelContainer>
        </div>
      </div>

      <BasicButton
        title={!!tank ? "Update" : "Create"}
        onClick={updateTank}
        style={{ margin: `2rem 0 0 0` }}
      />
    </>
  );
};

const DeleteTank = ({ tank, tanks, setTanks, docRef, toggle }) => {
  const deleteTank = async (e) => {
    e.preventDefault();

    const pI = tanks?.findIndex((p) => `${p.tank_id}` === `${tank.tank_id}`);

    let newObj = [];
    if (pI >= 0) {
      newObj = tanks.filter((p, i) => `${i}` !== `${pI}`);
      // console.log("newObj ", newObj);
      try {
        await updateDoc(docRef, { tanks: newObj });
        setTanks(newObj);
        toggle();
      } catch (e) {
        console.error("Error deleting document: ", e);
      }
    } else {
      alert("Product not selected");
    }
  };

  return (
    <>
      <div>
        <h2 className={modalCSS.dip__modal_title}>Delete tank ? </h2>

        <ResponsiveTable r={[tank]} />

        <BasicButton
          title={"Confirm Deletion"}
          onClick={deleteTank}
          style={{ margin: `2rem 0 0 0` }}
        />
      </div>
    </>
  );
};

export default Tanks;
