import React, { useState } from "react";
import logo from "../../logo.svg";
import Link from "../../utils/Navigation/Link";
import { Close, Hamburger } from "../SVG";
import "./navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const closeMenu = () => {
    setToggleMenu(false);
  };

  return (
    <div className="dip__navbar">
      <div className="dip__navbar-links">
        <div className="dip__navbar-links_logo">
          <Link href="/" className="item" closeMenu={closeMenu}>
            <img src={logo} />
          </Link>
        </div>
        <div className="dip__navbar-links_container">
          <LeftLinks closeMenu={closeMenu} />
        </div>
      </div>
      <div className="dip__navbar-sign">
        <RightLinks closeMenu={closeMenu} />
      </div>
      <div className="dip__navbar-menu">
        {toggleMenu ? (
          <Close
            size={27}
            color={"#000"}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <Hamburger
            size={27}
            color={"#000"}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="dip__navbar-menu_container scale-up-center">
            <div className="dip__navbar-menu_container-links">
              <LeftLinks closeMenu={closeMenu} />
            </div>
            <div className="dip__navbar-menu_container-links-sign">
              <RightLinks closeMenu={closeMenu} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;

const LeftLinks = ({ closeMenu }) => (
  <>
    <p>
      <Link href="/product" className="item" closeMenu={closeMenu}>
        Product
      </Link>
      {/* <a href="#possibility">Open AI</a> */}
    </p>
    <p>
      <Link href="/tank" className="item" closeMenu={closeMenu}>
        Tank
      </Link>
      {/* <a href="#features">Case Studies</a> */}
    </p>
    <p>
      {/* <Link href="/decantation" className="item" closeMenu={closeMenu}> */}
      <Link href="/" className="item" closeMenu={closeMenu}>
        Decantation
      </Link>
      {/* <a href="#blog">Library</a> */}
    </p>
  </>
);

const RightLinks = ({ closeMenu }) => (
  <>
    <p>
      <Link href="/signin" className="item" closeMenu={closeMenu}>
        Sign in
      </Link>
    </p>
    <Link href="/signin" className="item" closeMenu={closeMenu}>
      <button type="button">Sign up</button>
    </Link>
  </>
);
