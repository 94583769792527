// import firebase from "firebase";

// const firebaseConfig = {
// apiKey: "AIzaSyATKyTSJVN7-Zx60WQ66kkHo3nBhuMhYDs",
// authDomain: "meteor-3fd94.firebaseapp.com",
// projectId: "meteor-3fd94",
// storageBucket: "meteor-3fd94.appspot.com",
// messagingSenderId: "391620415322",
// appId: "1:391620415322:web:6848292646d9e91e6e6d63",
// measurementId: "G-69J20TCH7X",
// };

// const firebaseApp = firebase.initializeApp(firebaseConfig);
// const db = firebase.firestore();

// export default db;

// Import the functions you need from the SDKs you need
import firebase, { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
// console.log("db ", db);

// export const db = firebase.firestore();
// export const db = "";
