import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { BasicButton } from "../../../components/Button";
import InputLabelContainer from "../../../components/Input/input";
import Modal from "../../../components/Modal";
import useModal from "../../../components/Modal/useModal";
import { Trash } from "../../../components/SVG";
import { db } from "../../../firebase";
import modalCSS from "../../../utils/StyleSheets/modal.module.css";
import "./products.css";

const Products = ({ dlrID }) => {
  const [selectedProd, setSelectedProd] = useState();

  const {
    isOpen: isOpenNewUpdateModal,
    toggle: toggleNewUpdateModal,
    close: closeNewUpdateModal,
  } = useModal();
  const onCloseUpdate = () => {
    setSelectedProd();
    closeNewUpdateModal();
  };

  const {
    isOpen: isOpenDeleteModal,
    toggle: toggleDeleteModal,
    close: closeDeleteModal,
  } = useModal();
  const onCloseDelete = () => {
    setSelectedProd();
    closeDeleteModal();
  };

  const onClickDelete = (p) => {
    setSelectedProd(p);
    toggleDeleteModal();
  };

  const [products, setProducts] = useState([]);

  const docRef = doc(db, "dealers", `${dlrID}`);

  const fetchPost = async () => {
    try {
      const ps = await getDoc(docRef);
      if (ps.exists()) {
        const allProducts = ps.data().products;

        setProducts(allProducts);
      } else {
        console.log(`No such dealer ${dlrID} exists`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPost();
  }, []);

  return (
    <>
      <div className="dip__products section__padding">
        <div className="dip__products-content">
          <h2>Products</h2>

          <BasicButton
            title={"Add New Product"}
            onClick={() => {
              toggleNewUpdateModal();
              // delete update product data
              setSelectedProd();
            }}
          />

          <div className="dip__products-content__list">
            {products?.map((p, i) => (
              <div
                key={i}
                onClick={() => {
                  setSelectedProd(p);
                  toggleNewUpdateModal();
                }}
                className="dip__products-content__list-item"
              >
                <p className="dip__products-content__list-item-id">
                  {p.prod_id}
                </p>
                <p>{p.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpenNewUpdateModal}
        toggle={toggleNewUpdateModal}
        onEscape={onCloseUpdate}
        // close={onCloseUpdate}
      >
        <New_Update_Prod
          prod={selectedProd}
          products={products}
          setProducts={setProducts}
          docRef={docRef}
          toggle={toggleNewUpdateModal}
          onClickDelete={onClickDelete}
        />
      </Modal>

      <Modal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        onEscape={onCloseDelete}
        close={onCloseDelete}
      >
        <Delete_Prod
          prod={selectedProd}
          products={products}
          setProducts={setProducts}
          docRef={docRef}
          toggle={toggleDeleteModal}
        />
      </Modal>
    </>
  );
};

const New_Update_Prod = ({
  prod,
  products,
  setProducts,
  docRef,
  toggle,
  onClickDelete,
}) => {
  const [prod_id, set_prod_id] = useState(prod ? prod.prod_id : "");
  const [prod_name, set_prod_name] = useState(prod ? prod.name : "");

  const updateProduct = async (e) => {
    e.preventDefault();

    const updateProd =
      !!prod &&
      products?.findIndex((p) => `${p.prod_id}` === `${prod.prod_id}`);

    let newObj = [];
    if (!prod_id || `${prod_id}` === "0") {
      alert("Please enter valid product ID");
      return;
    }
    if (!prod_name) {
      alert("Please enter product name");
      return;
    }
    const sameIDProd = products.find((p) => `${p.prod_id}` === `${prod_id}`);
    if (!prod && !!sameIDProd) {
      alert("Product with same ID exists");
      return;
    }
    const sameNameProd = products.find(
      (p) =>
        `${p.name}`.toLowerCase() === String(prod_name).toLowerCase().trim()
    );
    if (!prod && !!sameNameProd) {
      alert("Product with same name exists");
      return;
    }

    const prodObj = { prod_id: prod_id, name: prod_name };

    if (!!prod && updateProd >= 0) {
      newObj = [...products];
      newObj[updateProd] = prodObj;
      // console.log("newObj ", newObj);
    } else {
      const newProd = prodObj;
      newObj = [...products, newProd];
    }

    try {
      await updateDoc(docRef, { products: newObj });
      setProducts(newObj);
      set_prod_id("");
      set_prod_name("");
      toggle();
    } catch (e) {
      console.error(`Error ${updateProd >= 0 ? "updating" : "adding"} : `, e);
    }
  };

  return (
    <div>
      <h2 className={modalCSS.dip__modal_title}>
        {!prod ? "New" : "Update"} Product
      </h2>

      {!!prod && (
        <div
          className={modalCSS.dip__modal_delete_text}
          onClick={() => onClickDelete(prod)}
        >
          <Trash size={16} color={"#FF4820"} />
          <span>Delete Product ? </span>
        </div>
      )}

      <InputLabelContainer label={"Product ID"}>
        <input
          value={prod_id}
          type="text"
          placeholder="Product ID"
          onChange={(e) => set_prod_id(e.target.value)}
        />
      </InputLabelContainer>

      <InputLabelContainer label={"Product name"}>
        <input
          value={prod_name}
          type="text"
          placeholder="Name of new product?"
          onChange={(e) => set_prod_name(e.target.value)}
        />
      </InputLabelContainer>

      <BasicButton
        title={!!prod ? "Update" : "Create"}
        onClick={updateProduct}
        style={{ margin: `2rem 0 0 0` }}
      />
    </div>
  );
};

const Delete_Prod = ({ prod, products, setProducts, docRef, toggle }) => {
  const deleteProduct = async (e) => {
    e.preventDefault();

    const pI = products?.findIndex((p) => `${p.prod_id}` === `${prod.prod_id}`);

    let newObj = [];
    if (pI >= 0) {
      newObj = products.filter((p, i) => `${i}` !== `${pI}`);
      // console.log("newObj ", newObj);
      try {
        await updateDoc(docRef, { products: newObj });
        setProducts(newObj);
        toggle();
      } catch (e) {
        console.error("Error deleting document: ", e);
      }
    } else {
      alert("Product not selected");
    }
  };

  return (
    <div>
      <h2 className={modalCSS.dip__modal_title}>Delete product ? </h2>

      <div className="dip__products-content__list-item">
        <p className="dip__products-content__list-item-id">{prod?.prod_id}</p>
        <p>{prod?.name}</p>
      </div>

      <BasicButton
        title={"Confirm Deletion"}
        onClick={deleteProduct}
        style={{ margin: `2rem 0 0 0` }}
      />
    </div>
  );
};

export default Products;
