import React, { useState } from "react";
import {
  useGetData,
  useGetDataDateRange,
  useGetDoc,
} from "../../../firebase/methods";
import { Trash, Edit, Close } from "../../../components/SVG";
import { db } from "../../../firebase";
import { doc, deleteDoc } from "firebase/firestore";
import "./index.css";
import { ResponsiveDecan } from "../../../components/Table";
import Modal from "../../../components/Modal";
import New from "../New";
import useModal from "../../../components/Modal/useModal";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const App = ({ dlrID }) => {
  const [fields] = useGetDoc({ path: `dealers/${dlrID}` });
  const products = fields && fields.products;
  const tanks = fields && fields.tanks;
  //   new Date(firebaseDate.toDate())

  const [selectedMonth, setSelectedMonth] = useState(
    Number(new Date().getMonth())
  );
  const [selectedYear, setSelectedYear] = useState(
    Number(new Date().getFullYear())
  );

  const [selectedDecan, setSelectedDecan] = useState();

  const currentMonthFirstDay = new Date(selectedYear, selectedMonth, 1);
  const currentMonthLastDay = new Date(selectedYear, selectedMonth + 1, 1);

  // console.log(currentMonthLastDay);

  const [decantations] = useGetDataDateRange({
    path: `dealers/${dlrID}/decantations`,
    startDT: currentMonthFirstDay,
    endDT: currentMonthLastDay,
  });
  // console.log(decantations);

  // Create an object to store the sum of rcvd_qty grouped by tank_id
  let sumByTank = {};

  // Iterate through the array of objects
  decantations?.forEach((item) => {
    if (item.tanks) {
      item.tanks.forEach((tank) => {
        const tankId = `TANK ${tank.tank_id}`;
        const rcvdQty = tank.rcvd_qty;
        if (sumByTank[tankId]) {
          sumByTank[tankId] += rcvdQty;
        } else {
          sumByTank[tankId] = rcvdQty;
        }
      });
    }
  });

  sumByTank["Count"] = decantations?.length;

  // console.log(sumByTank);

  const TableTank = ({ dTanks }) => {
    const data = dTanks?.map((t, i) => {
      const tankData = tanks?.find(
        (tank) => `${tank.tank_id}` === `${t.tank_id}`
      );
      const prodData = products?.find(
        (prod) => `${prod.prod_id}` === `${tankData.prod_id}`
      );

      return {
        ["tank id"]: t.tank_id,
        ["t qty rec."]: `${t.rcvd_qty} Lts.`,
        start_dip: t.start_dip,
        end_dip: t.end_dip,
        diff: Number(t.diff).toFixed(3),
        // product: "Unleaded Petrol",
        zproduct: prodData?.name,
        bgColor: tankData?.color,
      };
    });

    return (
      <ResponsiveDecan
        r={data}
        onClickEdit={() => {}}
        sortAsc={false}
        skipItems={["bgColor"]}
      />
    );
  };

  return (
    <>
      {true && (
        <div className="dip__decanList-summary section__margin">
          <div style={{ flexDirection: "row" }}>
            <select
              value={selectedMonth}
              onChange={(event) => {
                // console.log(event.target.name);
                // console.log(event.target.value);
                // this.setState({ [event.target.name]: event.target.value });
                setSelectedMonth(Number(event.target.value));
              }}
            >
              {months.map((month, index) => {
                return (
                  <option value={index} selected={selectedMonth == index}>
                    {month}
                  </option>
                );
              })}
            </select>

            <select
              value={selectedYear}
              onChange={(event) => {
                // console.log(event.target.name);
                // console.log(event.target.value);
                // this.setState({ [event.target.name]: event.target.value });
                setSelectedYear(Number(event.target.value));
              }}
            >
              {["2023"].map((year, index) => {
                return (
                  <option value={index} selected={selectedYear == index}>
                    {year}
                  </option>
                );
              })}
            </select>

            <div>
              <h5>Tank Summary</h5>
              <div className="row">
                {Object.keys(sumByTank).map((tank, index) => (
                  <div key={index} className="tank">
                    <div className="tank-label">{tank}</div>
                    <div className="tank-value">{sumByTank[tank]}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {decantations
            .sort(
              (a, b) => Date.parse(new Date(b.id)) - Date.parse(new Date(a.id))
            )
            .map((d, index) => (
              <div key={index} className="dip__decanList-content">
                <div key={index} className="dip__decanList-content-header">
                  <div>
                    <div className="dip__decanList-content-header__group__item">
                      <p>INV : </p>
                      <p className="valPara">{d.inv_no}</p>
                    </div>

                    <div className="dip__decanList-content-header__group__item">
                      <p>{new Date(d.id).toDateString()}</p>
                      <p className="valPara">
                        {new Date(d.id).toLocaleTimeString()}
                      </p>
                    </div>
                  </div>

                  <div className="dip__decanList-content-header__groupB">
                    <div
                      className="dip__decanList-content__btn"
                      onClick={() => {
                        setSelectedDecan(d);
                        // toggleNewUpdateModal();
                      }}
                    >
                      <button>
                        <Edit size={25} />
                      </button>
                    </div>
                    <div>
                      <div className="dip__decanList-content-header__group__item">
                        <p>Receipt : </p>
                        <p className="valPara">{d.rcvd_qty} Ltrs.</p>
                      </div>
                      <div className="dip__decanList-content-header__group__item">
                        <p>TT : </p>
                        <p className="valPara">{d.tt_no}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <TableTank dTanks={d.tanks} />
              </div>
            ))}
        </div>
      )}

      {/* <pre style={{ textAlign: "left" }}>{JSON.stringify(tanks, null, 2)}</pre> */}
    </>
  );
};

export default App;
