import React, { useState } from "react";
import {
  useGetData,
  useGetDataLimit,
  useGetDoc,
} from "../../../firebase/methods";
import { Trash, Edit, Close } from "../../../components/SVG";
import { db } from "../../../firebase";
import { doc, deleteDoc } from "firebase/firestore";
import "./index.css";
import { ResponsiveDecan } from "../../../components/Table";
import Modal from "../../../components/Modal";
import New from "../New";
import useModal from "../../../components/Modal/useModal";

const App = ({ dlrID }) => {
  const [limit, setLimit] = useState(5);
  const [decantations] = useGetDataLimit({
    path: `dealers/${dlrID}/decantations`,
    limitCount: limit,
  });
  const [fields] = useGetDoc({ path: `dealers/${dlrID}` });
  const products = fields && fields.products;
  const tanks = fields && fields.tanks;
  //   new Date(firebaseDate.toDate())

  const [selectedDecan, setSelectedDecan] = useState();

  const {
    isOpen: isOpenNewUpdateModal,
    toggle: toggleNewUpdateModal,
    close: closeNewUpdateModal,
  } = useModal();
  const onCloseUpdate = () => {
    setSelectedDecan();
    closeNewUpdateModal();
  };

  const deleteDecan = async (date) => {
    try {
      const docRef = !!date && doc(db, `dealers/${dlrID}/decantations/${date}`);
      await deleteDoc(docRef);
      window.location.reload();
    } catch (err) {
      console.log("error are ", err);
    }
  };

  const onClickDel = (d) => {
    window.confirm("Are you sure you want to delete this Entry?") &&
      deleteDecan(d.id);
  };

  const TableTank = ({ dTanks }) => {
    const data = dTanks?.map((t, i) => {
      const tankData = tanks?.find(
        (tank) => `${tank.tank_id}` === `${t.tank_id}`
      );
      const prodData = products?.find(
        (prod) => `${prod.prod_id}` === `${tankData.prod_id}`
      );

      return {
        ["tank id"]: t.tank_id,
        ["t qty rec."]: `${t.rcvd_qty} Lts.`,
        start_dip: t.start_dip,
        end_dip: t.end_dip,
        diff: Number(t.diff).toFixed(3),
        // product: "Unleaded Petrol",
        zproduct: prodData?.name,
        bgColor: tankData?.color,
      };
    });

    return (
      <ResponsiveDecan
        r={data}
        onClickEdit={() => {}}
        sortAsc={false}
        skipItems={["bgColor"]}
      />
    );
  };

  return (
    <>
      {!isOpenNewUpdateModal && (
        <div className="dip__decanList section__margin">
          {decantations
            .sort(
              (a, b) => Date.parse(new Date(b.id)) - Date.parse(new Date(a.id))
            )
            .map((d, index) => (
              <div key={index} className="dip__decanList-content">
                <div key={index} className="dip__decanList-content-header">
                  <div>
                    <div className="dip__decanList-content-header__group__item">
                      <p>INV : </p>
                      <p className="valPara">{d.inv_no}</p>
                    </div>

                    <div className="dip__decanList-content-header__group__item">
                      <p>{new Date(d.id).toDateString()}</p>
                      <p className="valPara">
                        {new Date(d.id).toLocaleTimeString()}
                      </p>
                    </div>
                  </div>

                  <div className="dip__decanList-content-header__groupB">
                    <div
                      className="dip__decanList-content__btn"
                      onClick={() => {
                        setSelectedDecan(d);
                        toggleNewUpdateModal();
                      }}
                    >
                      <button>
                        <Edit size={25} />
                      </button>
                    </div>
                    <div>
                      <div className="dip__decanList-content-header__group__item">
                        <p>Receipt : </p>
                        <p className="valPara">{d.rcvd_qty} Ltrs.</p>
                      </div>
                      <div className="dip__decanList-content-header__group__item">
                        <p>TT : </p>
                        <p className="valPara">{d.tt_no}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <TableTank dTanks={d.tanks} />
              </div>
            ))}
        </div>
      )}

      {isOpenNewUpdateModal && (
        <>
          <div className="dip__decanList-updateDecan">
            <div
              className="dip__decanList-content__btn"
              onClick={() => onClickDel(selectedDecan)}
            >
              <button style={{ backgroundColor: "#FFBFBF" }}>
                <Trash size={25} />
              </button>
            </div>
            <div
              className="dip__decanList-content__btn"
              onClick={() => onCloseUpdate()}
            >
              <button style={{}}>
                <Close size={25} />
              </button>
            </div>
          </div>

          <New
            dlrID={dlrID}
            decan={selectedDecan}
            toggle={toggleNewUpdateModal}
          />
        </>
      )}

      {/* <pre style={{ textAlign: "left" }}>{JSON.stringify(tanks, null, 2)}</pre> */}
    </>
  );
};

export default App;
