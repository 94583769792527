import * as React from "react";

const SvgComponent = (props) => {
  const width = props.size ? props.size : 37;
  const height = (width * 37) / 34;

  return (
    <svg
      width={width} // 371
      height={height} // 345
      viewBox="0 0 371 345"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 318h371v26.5H0V318ZM310.05 92.75c10.6-10.6 10.6-26.5 0-37.1l-47.7-47.7c-10.6-10.6-26.5-10.6-37.1 0L26.5 206.7v84.8h84.8L310.05 92.75ZM243.8 26.5l47.7 47.7-39.75 39.75-47.7-47.7L243.8 26.5ZM53 265v-47.7L185.5 84.8l47.7 47.7L100.7 265H53Z"
        fill="#000"
      />
    </svg>
  );
};

export default SvgComponent;
