import { useState } from "react";

export default function useModal() {
  const [isOpen, setisOpen] = useState(false);
  const toggle = () => setisOpen(!isOpen);
  const close = () => setisOpen(false);
  return { isOpen, toggle, close };
}

// import React, { useState } from "react";

// import Modal from "../../components/Modal";
// import useModal from "../../components/Modal/useModal";

// const App = () => {
//   const { isOpen, toggle } = useModal();
//   return (
//     <div>
//       <button onClick={toggle}>Open Modal </button>
//       <Modal isOpen={isOpen} toggle={toggle}>
//         <div>Yaay!!! Our Modal is rendered Properly.</div>
//       </Modal>
//     </div>
//   );
// };

// export default App;
