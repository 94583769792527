import * as React from "react";

const SvgComponent = (props) => {
  const width = props.size ? props.size : 38;
  const height = (width * 38) / 47;

  const fillColor = props.color ? props.color : "#000";
  return (
    <svg
      width={width} //  381
      height={height} // 478
      viewBox="0 0 381 478"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="a" fill="#fff">
        <path d="M111 40c0-22.091 17.909-40 40-40h79c22.091 0 40 17.909 40 40v47H111V40Z" />
      </mask>
      <path
        d="M81 40c0-38.66 31.34-70 70-70h79c38.66 0 70 31.34 70 70h-60c0-5.523-4.477-10-10-10h-79c-5.523 0-10 4.477-10 10H81Zm189 47H111h159ZM81 87V40c0-38.66 31.34-70 70-70v60c-5.523 0-10 4.477-10 10v47H81ZM230-30c38.66 0 70 31.34 70 70v47h-60V40c0-5.523-4.477-10-10-10v-60Z"
        fill={fillColor}
        mask="url(#a)"
      />
      <path
        d="m45 110 20 320c.95 18.49 14.4 32 32 32h184c17.67 0 30.87-13.51 32-32l20-320"
        stroke={fillColor}
        strokeWidth={32}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path fill={fillColor} d="M0 87h381v29H0z" />
      <path
        stroke={fillColor}
        strokeWidth={30}
        d="m121.01 413.62-10-269.061M189 413.246V144M255 413.246 265.5 146.5"
      />
    </svg>
  );
};

export default SvgComponent;
