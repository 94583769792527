import * as React from "react";

const SvgComponent = (props) => {
  const width = props.size ? props.size : 34;
  const height = (width * 34) / 34;

  const lineColor = props.color ? props.color : "#000";
  const strokeColor = props.color ? props.color : "#000";
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 344 344"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={172} cy={172} r={162} stroke={strokeColor} strokeWidth={20} />
      <path
        d="M222.696 232.893 111.291 120.152a6.678 6.678 0 0 1 9.486-9.402l111.741 112.409a6.915 6.915 0 1 1-9.822 9.734Z"
        fill={lineColor}
        stroke={strokeColor}
        strokeWidth={5.824}
      />
      <path
        d="m110.949 222.951 112.877-111.646a6.69 6.69 0 0 1 9.422 9.498L120.704 232.785a6.925 6.925 0 0 1-9.796-.027 6.925 6.925 0 0 1 .041-9.807Z"
        fill={lineColor}
        stroke={strokeColor}
        strokeWidth={5.824}
      />
    </svg>
  );
};

export default SvgComponent;
