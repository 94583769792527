import React from "react";
import "./input.css";

const InputLabelContainer = ({ label = "", ...props }) => {
  return (
    <div className="dip__component-input">
      <label>{label}</label>
      {props.children}
    </div>
  );
};

export default InputLabelContainer;
