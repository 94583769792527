export const MM_YYYY = (date) => {
  var MyDate = new Date(date);
  var year = MyDate.getFullYear();
  const month = ("0" + (MyDate.getMonth() + 1)).slice(-2);
  return month + "_" + year;
};

export const DD_HHMM = (date) => {
  var MyDate = new Date(date);

  var day = ("0" + MyDate.getDate()).slice(-2);
  var hours = ("0" + MyDate.getHours()).slice(-2);
  var minutes = ("0" + MyDate.getMinutes()).slice(-2);

  return day + "_" + hours + minutes;
};

export const SS_MILLI = (date) => {
  var MyDate = new Date(date);

  var seconds = ("0" + MyDate.getSeconds()).slice(-2);
  // var milliSeconds = ("0" + MyDate.getMilliseconds()).slice(-2);
  var milliSeconds = MyDate.getMilliseconds();

  return seconds + "_" + milliSeconds;
};

export const yyyyMMDD = (date) => {
  var yourDate = new Date(date);
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  return yourDate.toISOString().split("T")[0];
};
