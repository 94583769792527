import React from "react";

const Link = ({ className, href, children, closeMenu }) => {
  // console.log(window.location.pathname);
  // console.log(href);
  const active = href === window.location.pathname;

  const onClick = (event) => {
    // if ctrl or meta key are held on click, allow default behavior of opening link in new tab
    if (event.metaKey || event.ctrlKey) {
      return;
    }

    // prevent full page reload
    event.preventDefault();
    closeMenu();
    // update url
    window.history.pushState({}, "", href);

    // communicate to Routes that URL has changed
    const navEvent = new PopStateEvent("popstate");
    window.dispatchEvent(navEvent);
  };

  return (
    <a
      className={className}
      style={{
        fontWeight: active ? "bolder" : "normal",
        textDecorationLine: active ? "underline" : "none",
      }}
      href={href}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

export default Link;
