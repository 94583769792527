import React, { ReactNode, useEffect } from "react";
import Close from "../SVG/icons/close";

// interface ModalType {
//   children?: ReactNode;
//   isOpen: boolean;
//   toggle: () => void;
// }

const Modal = (props) => {
  const { onEscape = () => {}, close = undefined, customStyle = {} } = props;
  useEffect(() => {
    const keyDownHandler = (event) => {
      // console.log("User pressed: ", event.key);
      if (event.key === "Escape") {
        event.preventDefault();
        // 👇️ your logic here
        onEscape();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    // 👇️ clean up event listener
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <div style={{ ...style.all }}>
      {props.isOpen && (
        <div
          className="modal-overlay"
          style={{ ...style.modalOverlay }}
          onClick={props.toggle}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{ ...style.modalBox, position: "relative", ...customStyle }}
            className="modal-box"
          >
            {!!close && (
              <div
                onClick={close}
                style={{ position: "absolute", right: 0, top: 0 }}
              >
                <Close />
              </div>
            )}
            {props.children}
          </div>
        </div>
      )}
    </div>
  );
};

const style = {
  all: {
    margin: 0,
    padding: 0,
  },
  modalOverlay: {
    zIndex: 9999,
    width: "100vw",
    height: "100vh",
    position: "absolute",
    top: 0,
    // background: rgba(0, 0, 0, 0.7),
    background: "#0008",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // overflow: "auto",
  },
  modalBox: {
    display: "block",
    background: "white",
    width: "70%",
    // height: "70%",
    padding: "1rem",
    borderRadius: "1rem",
    overflow: "auto",
  },
};

export default Modal;

// * {
//     margin: 0;
//     padding: 0;
//   }
//   .modal-overlay {
//     z-index: 9999;
//     width: 100vw;
//     height: 100vh;
//     position: absolute;
//     top: 0;
//     background: rgba(0, 0, 0, 0.7);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

//   .modal-box {
//     display: block;
//     background: white;
//     width: 70%;
//     height: 70%;
//     padding: 1rem;
//     border-radius: 1rem;
//   }
