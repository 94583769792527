import React, { useState, useEffect } from "react";
import { BasicButton } from "../../../components/Button";
import InputLabelContainer from "../../../components/Input/input";
import "./index.css";
import { diff, volume } from "../../../methods";

const New = ({ tank, onTankEntry, tankEntry = "" }) => {
  const [rcvdQty, setrcvdQty] = useState("");
  const [startDip, setStartDip] = useState("");
  const [endDip, setEndDip] = useState("");

  const [answer, setAnswer] = useState("");
  const [errorText, seterrorText] = useState("");

  // console.log("ans ", tankEntry);

  useEffect(() => {
    if (!!tankEntry) {
      setrcvdQty(tankEntry.rcvd_qty ? tankEntry.rcvd_qty : "");
      setStartDip(tankEntry.start_dip ? tankEntry.start_dip : "");
      setEndDip(tankEntry.end_dip ? tankEntry.end_dip : "");
    }
  }, [tankEntry]);

  const selectedTank = tank;

  const tankMeasure = {
    diameter: selectedTank.dia,
    length: selectedTank.length,
    P1: selectedTank.p1,
    P2: selectedTank.p2,
    deadwood: selectedTank.deadwood,
  };

  useEffect(() => {
    // if (!tank) {
    //   seterrorText("Product tank not selected");
    //   setAnswer("");
    // }
    // if (Number(rcvdQty) < 0) {
    //   seterrorText("Please select received quantity");
    //   setAnswer("");
    // }
    // if (!startDip) {
    //   seterrorText("Please select start dip");
    //   setAnswer("");
    // }
    // if (!endDip) {
    //   seterrorText("Please select end dip");
    //   setAnswer("");
    // }

    if (
      !!Number(startDip) &&
      !!Number(endDip) &&
      Number(rcvdQty) >= 0 &&
      selectedTank
    ) {
      const ans = diff({
        start: startDip,
        end: endDip,
        rcvd: rcvdQty,
        tank: tankMeasure,
      });
      setAnswer(ans);
      onTankEntry({
        diff: Number(ans),
        end_dip: Number(endDip),
        rcvd_qty: Number(rcvdQty),
        start_dip: Number(startDip),
        tank_id: tank.tank_id,
      });
    }
  }, [rcvdQty, startDip, endDip]);

  const result = answer !== "" && Number(answer);

  const startVol =
    !!startDip &&
    !!selectedTank &&
    volume({
      ...tankMeasure,
      height: startDip,
    });

  const endVol =
    !!endDip &&
    !!selectedTank &&
    volume({
      ...tankMeasure,
      height: endDip,
    });

  return (
    <>
      <div
        className="dip__newdecan-tankEntry"
        style={{ backgroundColor: tank.color }} // enter tank color instead of tank name field
      >
        <div className="dip__newdecan-tankEntry__group1">
          <div
            className="dip__newdecan-input"
            style={{ justifyContent: "center" }}
          >
            <div className="dip__newdecan-tankEntry__groupContent-tankNameContainer">
              <label>Tank No.: </label>
              <p>{tank.tank_id}</p>
              {/* <p>{tank.name}</p> */}
            </div>
            {/* <div style={{ height: "1rem" }} /> */}
            <div className="dip__newdecan-tankEntry__groupContent-tankNameContainer">
              {/* <div className="dip__newdecan-tankEntry__groupContent-moreInfo"> */}
              <label>Product: </label>
              <p>{tank.prod_id}</p>
            </div>
          </div>

          <div className="dip__newdecan-input">
            <label>Qty Rec.</label>
            <input
              onChange={(e) => {
                setrcvdQty(e.target.value);
              }}
              step="any"
              type="number"
              placeholder="Qty"
              value={rcvdQty}
            />

            <div className="dip__newdecan-tankEntry__groupContent-diffContainer">
              <label>Diff</label>
              <p
                // style={{ backgroundColor: result < 0 ? "#91C794" : "#F8A5A7" }}
                style={{ backgroundColor: result >= 0 ? "#91C794" : "#F8A5A7" }}
              >
                {Number(result).toFixed(2)}
              </p>
            </div>
          </div>
        </div>

        <div className="dip__newdecan-tankEntry__group2">
          <div className="dip__newdecan-input">
            <label>Start Dip</label>
            <input
              onChange={(e) => {
                setStartDip(e.target.value);
              }}
              step="any"
              className="input1-border input2-border"
              type="number"
              placeholder="Start dip"
              value={startDip}
            />
            <div className="dip__newdecan-tankEntry__groupContent-moreInfo">
              {/* <label>Vol:</label> */}
              <p> {!!startVol ? Number(startVol).toFixed(2) : "0.00"} Lts.</p>
            </div>
          </div>

          <div className="dip__newdecan-input">
            <label>End Dip</label>
            <input
              onChange={(e) => {
                setEndDip(e.target.value);
              }}
              step="any"
              className="input1-border input2-border"
              type="number"
              placeholder="End dip"
              value={endDip}
            />
            <div className="dip__newdecan-tankEntry__groupContent-moreInfo">
              {/* <label>Vol:</label> */}
              <p> {!!endVol ? Number(endVol).toFixed(2) : "0.00"} Lts.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default New;
