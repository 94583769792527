import "../App.css";
import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "./index";

export const useGetData = ({ path }) => {
  const [documents, setDocuments] = React.useState([]);
  const docData = async () => {
    const lists = await getDocs(collection(db, path));
    let arr = [];
    lists.docs.map((doc) => arr.push({ id: doc.id, ...doc.data() }));
    setDocuments(arr);
  };
  React.useEffect(() => {
    docData();
  }, [db]);
  return [documents];
};

export const useGetDataLimit = ({ path, limitCount = 5 }) => {
  const [documents, setDocuments] = React.useState([]);
  const docData = async () => {
    const lists = await getDocs(
      query(
        collection(db, path),
        orderBy("timestamp", "desc"),
        limit(limitCount)
      )
    );
    let arr = [];
    lists.docs.map((doc) => arr.push({ id: doc.id, ...doc.data() }));
    setDocuments(arr);
  };
  React.useEffect(() => {
    docData();
  }, [db]);
  return [documents];
};

export const useGetDataDateRange = ({ path, startDT, endDT }) => {
  const [documents, setDocuments] = React.useState([]);
  const docData = async () => {
    // console.log("startDT ", startDT);
    // console.log("endDT ", endDT);

    const lists = await getDocs(
      query(
        collection(db, path),
        // orderBy("__name__", "desc"),
        // // orderBy("createdAt", "desc"),
        // limit(40)

        // where("timestamp", ">", expirationDate)
        where("timestamp", ">=", startDT),
        where("timestamp", "<", endDT),
        // where("timestamp", ">=", firstDayOfLastMonth),
        // where("timestamp", "<", firstDayOfCurrentMonth),
        orderBy("timestamp", "desc")
        // where(
        //   "__name__",
        //   "==",
        //   "Fri Apr 07 2023 09:34:48 GMT+0530 (India Standard Time)"
        // )
        // where(
        //   documentId(),
        //   "==",
        //   "Fri Apr 07 2023 09:34:48 GMT+0530 (India Standard Time)"
        // )
      )
    );
    let arr = [];
    lists.docs.map((doc) => arr.push({ id: doc.id, ...doc.data() }));
    setDocuments(arr);
  };
  React.useEffect(() => {
    docData();
  }, [db, startDT, endDT]);
  return [documents];
};

export const useGetDoc = ({ path, field }) => {
  const [documents, setDocuments] = React.useState([]);
  const tanks = async () => {
    const docRef = doc(db, path);
    const ps = await getDoc(docRef);
    if (ps.exists()) {
      const allField = !!field ? ps.data()[field] : ps.data();
      // console.log(ps.data());
      // console.log(allProducts);
      setDocuments(allField);
    } else {
      console.log(`No data in ${path} exists`);
    }
  };
  React.useEffect(() => {
    tanks();
  }, [db]);
  return [documents];
};
