import React from "react";
import useWindowSize from "../../../utils/Hooks/useWindowSize";
import "./index.css";

const ResponsiveTable = ({
  r,
  onClickEdit,
  sortAsc = true,
  skipItems = [],
}) => {
  const { width } = useWindowSize();

  const Head = () => {
    // console.log(r);
    const obj = r && r[0];
    const detail = obj;

    const columns = Object.keys(detail)
      ?.sort((a, b) => (sortAsc ? a.localeCompare(b) : b.localeCompare(a)))
      .map((colName) => {
        if (skipItems.includes(colName)) {
          return;
        }
        return <th key={colName}>{colName}</th>;
      });
    return <tr>{columns}</tr>;
  };

  const Row = (row) => {
    const obj = row.row;
    const detail = Object.entries(obj);
    // console.log(detail);
    const values = detail
      ?.sort((a, b) =>
        sortAsc ? a[0].localeCompare(b[0]) : b[0].localeCompare(a[0])
      )
      .map((colName, colKey) => {
        // console.log(colName);

        if (skipItems.includes(colName[0])) {
          return;
        }

        return (
          <td
            key={colKey}
            data-label={colName[0]}
            onClick={() => onClickEdit(obj)}
          >
            {colName[0].toLowerCase() === "color" ? (
              <div className="colorContainer">
                <div
                  className="color"
                  style={{ backgroundColor: colName[1] }}
                />
              </div>
            ) : (
              colName[1]
            )}
          </td>
        );
      });
    return (
      <tr style={{ backgroundColor: `${obj.bgColor}` }} key={row}>
        {values}
      </tr>
    );
  };

  return (
    <table className="responsive-decan">
      {width < 600 ? <></> : <thead>{r && r.length > 0 && <Head />}</thead>}
      <tbody>
        {r &&
          r?.map((row, index) => {
            return <Row key={index} row={row} />;
          })}
      </tbody>
    </table>
  );
};

export default ResponsiveTable;
