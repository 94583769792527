import * as React from "react";

const SvgComponent = (props) => {
  const width = props.size ? props.size : 649;
  const height = (width * 649) / 447;

  const lineColor = props.color ? props.color : "#000";
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 649 447"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={lineColor}
        d="M0 0h648.588v82.639H0zM158 188h491v82H158zM0 364.359h648.588v82.639H0z"
      />
    </svg>
  );
};

export default SvgComponent;
