import "./index.css";
import React, { useEffect, useState } from "react";
import { useGetDoc } from "../../../firebase/methods";
import { setDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase";
import { yyyyMMDD } from "../../../methods/date";
// import TankEntry from "./index";
import TankEntry from "./tankEntry";
import { BasicButton } from "../../../components/Button";

const App = ({ dlrID, decan = null, toggle = () => {} }) => {
  const [documents] = useGetDoc({ path: `dealers/${dlrID}`, field: "tanks" });

  const [rcvdQty, setrcvdQty] = useState(
    decan && decan.rcvd_qty ? decan.rcvd_qty : ""
  );
  const [errorText, seterrorText] = useState("");

  const [decanDate, setDecanDate] = useState(
    decan && decan.timestamp ? decan.timestamp.toDate() : new Date()
  );
  const dateFormat = yyyyMMDD(decanDate);
  const [inv_no, setinv_no] = useState(
    decan && decan.inv_no ? decan.inv_no : ""
  );
  const [tt_no, settt_no] = useState(decan && decan.tt_no ? decan.tt_no : "");

  const [tankEntry, setTankEntry] = useState([]);

  const onTankEntry = (data) => {
    if (!data) return;
    const hasTankData = tankEntry?.findIndex(
      (t) => `${t.tank_id}` === `${data.tank_id}`
    );
    // console.log("data ", data);
    let newObj = [];
    if (hasTankData >= 0) {
      // update
      newObj = [...tankEntry];
      newObj[hasTankData] = data;
      // console.log("newObj ", newObj);
      setTankEntry(newObj);
    } else {
      // append
      newObj = [...tankEntry, data];
      // console.log("newObj ", newObj);
      setTankEntry(newObj);
    }
  };

  useEffect(() => {
    decan && decan.tanks && setTankEntry(decan.tanks);
  }, [decan]);
  // console.log("tanks entries ", JSON.stringify(tankEntry, null, 2));

  useEffect(() => {
    const receivedQuantity = tankEntry
      .map((item) => Number(item.rcvd_qty))
      .reduce((prev, curr) => prev + curr, 0);
    // console.log(receivedQuantity);
    setrcvdQty(receivedQuantity);
  }, [tankEntry]);

  const addDecan = async (e) => {
    // e.preventDefault();

    if (Number(rcvdQty) < 0) {
      seterrorText("Please enter received quantity");
      return;
    }

    let tankdata = tankEntry.filter((t) => {
      if (t.rcvd_qty > 0) return t;
    });
    // console.log(tankdata);
    if (tankdata.length < 1) {
      seterrorText("Please enter received quantity in a tank");
      return;
    }

    const docRef = doc(
      db,
      `dealers/${dlrID}/decantations/${new Date(decanDate)}`
    );

    try {
      await setDoc(docRef, {
        rcvd_qty: Number(rcvdQty),
        tanks: tankdata,
        timestamp: new Date(decanDate),
        inv_no: inv_no,
        tt_no: tt_no,
        createdAt: new Date(),
      });

      // setProducts([...products, newProd]);
      setDecanDate(new Date());
      setrcvdQty("");
      setTankEntry([]);

      if (!!decan) {
        toggle();
      } else {
        window.location.reload();
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <div>
      <div
        className="dip__newdecan section__padding"
        style={{ paddingTop: "1rem" }}
      >
        <div className="dip__newdecan-content">
          <h2>{decan ? "Update" : "Supply"} Decantation</h2>

          <div className="dip__newdecan-content__header">
            <div className="dip__newdecan-content__header-child">
              {/* <label>Date : </label> */}
              <input
                placeholder="Choose your birthdate"
                type="datetime-local"
                value={
                  new Date(
                    new Date(decanDate).getTime() -
                      new Date(decanDate).getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .split(".")[0]
                }
                onChange={(e) => setDecanDate(e.target.value)}
              />
            </div>

            <div className="dip__newdecan-content__header-child">
              {/* <p>Inv No. </p> */}
              <input
                value={inv_no}
                type="text"
                placeholder="Invoice Number"
                onChange={(e) => setinv_no(e.target.value)}
              />
            </div>

            <div className="dip__newdecan-content__header-child">
              {/* <p>TT No.</p> */}
              <input
                value={tt_no}
                type="text"
                placeholder="Tanker Number"
                onChange={(e) => settt_no(e.target.value)}
              />
            </div>
          </div>

          {errorText && (
            <>
              <p style={{ color: "red" }}>{errorText}</p>
              <br></br>
            </>
          )}

          {(!documents || documents?.length < 1) && (
            <>
              <p style={{ color: "red", alignSelf: "center" }}>
                Please add a tank
              </p>
              <br></br>
            </>
          )}

          {documents?.map((d) => (
            <TankEntry
              key={d.tank_id}
              tank={d}
              tankEntry={tankEntry.find((f) => f.tank_id === d.tank_id)}
              onTankEntry={onTankEntry}
            />
          ))}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <label htmlFor="quantity">Received Quantity (in Ltrs.) : </label>
            <p style={{ paddingLeft: 10 }}>{rcvdQty} Ltrs.</p>
          </div>

          <BasicButton
            title={"Confirm"}
            onClick={() => {
              addDecan();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default App;
