import React from "react";
import Decan from "./pages/decan";
import { Products, Tanks } from "./pages/home";

import Navbar from "./components/Navbar/Navbar";
import Route from "./utils/Navigation/Route";
import "./App.css";

import CTA from "./components/CTA/CTA";
import Features from "./pages/Welcome/Features/features";
import Footer from "./pages/Welcome/Footer/footer";
import Header from "./pages/Welcome/Header/header";
import What from "./pages/Welcome/What/what";

const dlrID = process.env.REACT_APP_defaultDealer;

const App = () => {
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar />
      </div>

      <Route path="/home">
        <Header />
        <What />
        <Features />
        <CTA />
        <Footer />
      </Route>

      <Route path="/product">
        <Products dlrID={dlrID} />
      </Route>

      <Route path="/tank">
        <Tanks dlrID={dlrID} />
      </Route>

      {/* <Route path="/decantation"> */}
      <Route path="/">
        <Decan dlrID={dlrID} />
      </Route>

      <Route path="/signin">
        <What />
      </Route>
    </div>
  );
};

export default App;

// import logo from "./logo.svg";
// import "./App.css";
// import React, { useEffect, useState } from "react";
// // import NewDecan from "./pages/newDecan/Design";
// import NewDecan from "./pages/newDecan";
// import DealerHome from "./pages/home";
// import DecanList from "./pages/DecanList";

// const defaultScreen = "NewDecan";
// const screens = ["NewDecan", "DealerHome", "DecanList"];
// const dlrID = "169000";

// const App = () => {
//   const [selected, setSelected] = useState(defaultScreen);

//   return (
//     <div className="App">
//       <div
//         style={{
//           display: "flex",
//           flex: 1,
//           justifyContent: "space-around",
//           alignItems: "center",
//         }}
//       >
//         <p
//           onClick={() => setSelected("DealerHome")}
//           style={{ color: selected === "DealerHome" ? "blue" : "black" }}
//         >
//           Dealer Home
//         </p>
//         <p
//           onClick={() => setSelected("NewDecan")}
//           style={{ color: selected === "NewDecan" ? "blue" : "black" }}
//         >
//           Create Decantation
//         </p>

//         <p
//           onClick={() => setSelected("DecanList")}
//           style={{ color: selected === "DecanList" ? "blue" : "black" }}
//         >
//           Decantations
//         </p>
//       </div>

//       {selected === "NewDecan" && <NewDecan dlrID={dlrID} />}
//       {selected === "DealerHome" && <DealerHome dlrID={dlrID} />}
//       {selected === "DecanList" && <DecanList dlrID={dlrID} />}
//     </div>
//   );
// };

// export default App;
