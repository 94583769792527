export const volume = ({
  diameter,
  length,
  P1 = 0,
  P2 = 0,
  deadwood,
  height,
}) => {
  const radius = diameter / 2;
  const radiusSq = radius * radius;
  const ACOS = Math.acos(1 - height / radius);
  const P12 = P1 + P2;

  const left =
    (length + P12 / 2) * radiusSq * (ACOS - 0.5 * Math.sin(2 * ACOS)); // brackets in length

  const right =
    ((Math.sin(2 * ACOS) - 0.5 * Math.sin(4 * ACOS)) * P12 * radiusSq) / 12;

  const SUM = left - right;

  const formula1 = SUM / (1.000028 * 1000);
  const formula2 = (deadwood / diameter) * height;
  const finalFormula = formula1 - formula2;
  return finalFormula;
};

export const diff = ({ start, end, rcvd, tank = null }) => {
  if (!start) return "Please select start";
  if (!end) return "Please select end";
  if (!rcvd) return "Please select rcvd";
  if (!tank) return "Please select tank";
  const startingVol = volume({ ...tank, height: start });
  const endingVol = volume({ ...tank, height: end });
  const finalVolume = endingVol - startingVol;
  // const receivedVolume = rcvd * 1000;
  const receivedVolume = rcvd;
  const difference = finalVolume - receivedVolume;
  return difference;
};

export const tankHS = {
  diameter: 210,
  length: 625,
  P1: 0,
  P2: 0,
  deadwood: 18,
};
export const tankMS = {
  diameter: 224,
  length: 524,
  P1: 0,
  P2: 0,
  deadwood: 18,
};
export const tankXP = {
  diameter: 194,
  length: 548.4,
  P1: 0,
  P2: 0,
  deadwood: 16,
};
