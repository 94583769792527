import React, { useState } from "react";

import List from "./List";
import Report from "./Report";
import New from "./New";
// import New from "./New/newDecan";
// import New from "./New/newDecan/Design";

import "../../components/Tabs/index.css";

const Decan = ({ dlrID }) => {
  const [activeTab, setActiveTab] = useState("New");

  const handleTab0 = () => {
    setActiveTab("Report");
  };
  const handleTab1 = () => {
    setActiveTab("New");
  };
  const handleTab2 = () => {
    setActiveTab("List");
  };

  return (
    <div className="dip__component-Tabs">
      <ul className="dip__component-Tabs__nav">
        <li
          className={`${activeTab === "Report" ? "active" : ""} borderLeft`}
          onClick={handleTab0}
        >
          Report
        </li>
        <li
          className={activeTab === "New" ? "active" : ""}
          onClick={handleTab1}
        >
          New
        </li>
        <li
          className={`${activeTab === "List" ? "active" : ""} borderRight`}
          onClick={handleTab2}
        >
          List
        </li>
      </ul>

      <div className="dip__component-Tabs__outlet">
        {activeTab === "New" && <New dlrID={dlrID} />}
        {activeTab === "List" && <List dlrID={dlrID} />}
        {activeTab === "Report" && <Report dlrID={dlrID} />}

        {/* {activeTab === "New" ? <New dlrID={dlrID} /> : <List dlrID={dlrID} />} */}
      </div>
    </div>
  );
};
export default Decan;
