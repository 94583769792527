import React from "react";
import "./basicButton.css";

const Button = ({
  title = "Button",
  onClick = () => {},
  className = "",
  style = {},
}) => {
  return (
    <div className={`dip__component-button ${className} `} style={style}>
      <button type="submit" onClick={onClick}>
        {title}
      </button>
    </div>
  );
};

export default Button;
